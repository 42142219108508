.id-slider {
    width: 100%;
    .ant-slider {
        padding-left: 0px;
        padding-right: 0px;
        .ant-slider-track {
            background-color: var(--main-color);
        }
        .ant-slider-handle {
            border: solid 2px var(--main-color);
        }

        &:hover {
            .ant-slider-track {
                background-color: var(--main-color);
            }
            .ant-slider-handle {
                border: solid 2px var(--main-color);
            }
        }
    }
}
.id-input-number {
    .ant-input-number {
        &:hover {
            border-color: var(--main-color);
            border-right-width: 1px !important;
        }
    }
}

.cell-grid-editable {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .body-wrap,
    textarea {
        border: 0px !important;
        height: 100%;
        resize: none;
        min-height: unset !important;
        border-radius: 0px !important;
    }
    .textarea-input {
        padding: 3px 5px;
        line-height: 16px;
    }

    &.cell-grid-editable-text-area {
        border: 0px !important;
        .textarea:focus-within {
            border: 0px !important;
        }
    }
}

.action-row-overlay-menu {
    z-index: auto !important;
    .ant-popover-inner-content {
        padding: 0px;
        width: unset;
        .ant-menu {
            min-width: 160px;
            box-shadow:
                0 3px 6px -4px rgba(0, 0, 0, 0.12),
                0 6px 16px 0 rgba(0, 0, 0, 0.08),
                0 9px 28px 8px rgba(0, 0, 0, 0.05);
            border: 0px !important;
            .ant-menu-item {
                padding: 0px !important;
                margin: 0px !important;
                height: 36px;
                line-height: 36px;
            }

            .ant-menu-submenu {
                padding: 0px !important;
                margin: 0px !important;
                height: 36px;
                line-height: 36px;
                .ant-menu-submenu-title {
                    margin: 0px !important;
                    height: 36px !important;
                    padding: 0px 40px 0px 0px !important;
                    line-height: 36px;
                    .ant-menu-title-content {
                        padding-right: 16px !important;
                        display: flex;
                        height: 100%;
                    }
                }
            }

            .ant-menu-item,
            .ant-menu-submenu {
                &:hover {
                    background-color: #e6f7ff;
                }
                .action-btn {
                    padding: 0px;
                    height: 36px;
                    line-height: 36px;
                    margin: 0px;
                    background-color: transparent !important;

                    .id-grid-action-btn-icon {
                        width: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .ant-skeleton {
                            display: flex;
                        }
                    }
                    .id-grid-action-btn-text {
                        flex: 1;
                        padding-right: 10px;
                        .ant-skeleton {
                            display: flex;
                            .ant-skeleton-input {
                                min-width: 100%;
                            }
                        }
                    }
                }
            }
        }

        .action-row-overlay-menu-item {
            padding: 6px 15px;
            cursor: pointer;

            &:hover {
                background-color: #e6f7ff;
            }
        }

        .ant-divider-horizontal {
            margin: 0px !important;
        }
    }
}
.id-grid-action-row-sub-menu {
    .ant-menu {
        min-width: 160px;
        .ant-menu-item {
            padding: 0px !important;
            margin: 0px !important;
            height: 36px;
            line-height: 36px;
        }

        .ant-menu-submenu {
            padding: 0px !important;
            margin: 0px !important;
            height: 36px;
            line-height: 36px;
            .ant-menu-submenu-title {
                margin: 0px !important;
                height: 36px !important;
                padding: 0px 40px 0px 0px !important;
                line-height: 36px;
                .ant-menu-title-content {
                    padding-right: 16px !important;
                    display: flex;
                    height: 100%;
                }
            }
        }

        .ant-menu-item,
        .ant-menu-submenu {
            &:hover {
                background-color: #e6f7ff;
            }
            .action-btn {
                padding: 0px;
                height: 36px;
                line-height: 36px;
                margin: 0px;
                background-color: transparent !important;
                .id-grid-action-btn-icon {
                    width: 40px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .ant-skeleton {
                        display: flex;
                    }
                }
                .id-grid-action-btn-text {
                    flex: 1;
                    padding-right: 10px;
                    .ant-skeleton {
                        display: flex;
                        .ant-skeleton-input {
                            min-width: 100%;
                        }
                    }
                }
            }
        }
    }
}

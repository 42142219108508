.id-avatar-group-cell {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    .ant-avatar-string {
        font-size: 12px;
    }
}
.id-avatar-group-cell-tooltip {
    &.ant-tooltip {
        z-index: 9999 !important;
    }
}
.ant-avatar-string {
    font-size: var(--main-font-size);
}

.id-avatar-group-cell-popover {
    &.ant-popover-placement-top {
        padding-bottom: 5px !important;
    }
}
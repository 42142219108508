@import "./rc-select.scss";
@import "./indent.scss";
$boder-color-grid: var(--grid-border-color, #e9e9e9);
$header-background-grid: var(--grid-header-background, #f5f5f5);
$ag-row-odd-bg: var(--grid-row-odd-background, #f7fafb);
$ag-row-selected-bg: var(--grid-row-selected-background, #b7e4ff);
$boder-color-grid-focus: var(--main-color);

.action-btn {
    padding: 3px 6px;
    margin: 0 5px;
    border-radius: 3px;
    color: #fff !important;
    text-decoration: none;
    border: 1px solid transparent;
    font-size: 12px;
    outline: none !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-shadow:
        0 4px 6px rgba(50, 50, 93, 0.11),
        0 1px 3px rgba(0, 0, 0, 0.08);
    text-transform: uppercase;
    cursor: pointer;
    line-height: 15px;
    display: flex;
    align-items: center;
    min-height: 23px;

    i,
    .anticon {
        font-size: 15px;
    }

    &.action-btn-add {
        background: #007bff;
    }

    &.action-btn-config {
        background: #ff8041;
    }

    &.action-btn-upload {
        background: #1e8cc7;
    }

    &.action-btn-detail {
        background: #5e72e4;
    }

    &.action-btn-delete {
        background: #f5365c;
    }

    &.action-btn-edit {
        background: #2dce89;
    }

    &.action-btn-approval {
        background: #2dce89;
    }

    &.action-btn-reject {
        background: #f5365c;
    }
    &.action-btn-dropdown {
        background: transparent !important;
        color: #333 !important;
        box-shadow: unset !important;
    }

    &.action-row-sub-menu {
        padding: 10px;
        margin: 0px !important;
        font-size: 13px;
        box-shadow: none !important;
        background: #fff !important;
        &:hover {
            background: #e6f7ff !important;
        }
        .action-text {
            color: #333 !important;
            text-transform: initial;
            margin-left: 0px !important;
        }

        &.action-btn-edit {
            i,
            .anticon {
                color: #2dce89;
                font-size: 16px;
            }
        }

        &.action-btn-add {
            i,
            .anticon {
                color: #007bff;
                font-size: 16px;
            }
        }

        &.action-btn-config {
            i,
            .anticon {
                color: #ff8041;
                font-size: 16px;
            }
        }

        &.action-btn-upload {
            i,
            .anticon {
                color: #1e8cc7;
                font-size: 16px;
            }
        }

        &.action-btn-detail {
            i,
            .anticon {
                color: #5e72e4;
                font-size: 16px;
            }
        }

        &.action-btn-delete {
            i,
            .anticon {
                color: #f5365c;
                font-size: 16px;
            }
        }

        &.action-btn-edit {
            i,
            .anticon {
                color: #2dce89;
                font-size: 16px;
            }
        }

        &.action-btn-approval {
            i,
            .anticon {
                color: #2dce89;
                font-size: 16px;
            }
        }

        &.action-btn-reject {
            i,
            .anticon {
                color: #f5365c;
                font-size: 16px;
            }
        }
    }
}

.action-cell {
    display: flex;
    align-items: center;
    justify-content: center;
}

.show-vertical-show {
    .ag-body-viewport.ag-layout-normal {
        overflow-y: scroll !important;
    }
}

.ag-theme-balham {
    color: #333;
    font-size: var(--main-font-size);
    .group-tree-col {
        &:has(.id-grid-button-create-row) {
            padding: 0px !important;
        }
        .id-grid-button-create-row {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;
            height: 100%;
            border-radius: 3px;
            padding: 5px 10px;
        }
    }

    .ag-center-cols-container {
        .ag-row {
            .ag-cell {
                &:first-child {
                    border-left: 0px !important;
                }
            }
        }
    }

    .ag-icon-expanded,
    .ag-icon-contracted {
        transform: unset !important;
    }

    .id-row-summary {
        background: #f7fafb !important;
        font-weight: bold;
        .id-cell-summary-right {
            justify-content: flex-end !important;
            text-align: right !important;
            * {
                justify-content: flex-end !important;
                text-align: right !important;
            }
        }
        .id-cell-summary-left {
            justify-content: flex-start !important;
            text-align: left !important;
            * {
                justify-content: flex-start !important;
                text-align: left !important;
            }
        }
        .id-cell-summary-center {
            justify-content: center !important;
            text-align: center !important;
            * {
                justify-content: center !important;
                text-align: center !important;
            }
        }
    }

    .ag-floating-bottom {
        border-top: 1px solid $boder-color-grid;
        .ag-row:last-child {
            div.ag-cell {
                border-bottom: 0px !important;
            }
        }
    }

    .ag-floating-top {
        border-bottom: 1px solid $boder-color-grid;
        .ag-pinned-left-floating-top,
        .ag-floating-top-viewport,
        .ag-pinned-right-floating-top {
            .ag-row:last-child {
                div.ag-cell {
                    border-bottom: 0px !important;
                }
            }
        }
        .ag-floating-top-viewport {
            .ag-row:last-child {
                div.ag-cell:first-child {
                    border-left: 0px !important;
                }
            }
        }
    }

    .ag-menu {
        &:has(.ant-menu) {
            box-shadow:
                0 3px 6px -4px rgba(0, 0, 0, 0.12),
                0 6px 16px 0 rgba(0, 0, 0, 0.08),
                0 9px 28px 8px rgba(0, 0, 0, 0.05);
            border: 0px !important;
        }
        .ag-menu-list {
            .ag-menu-separator {
                & > span {
                    height: 4px !important;
                }
            }
            .ag-menu-option-popup-pointer {
                width: 30px !important;
            }
            .ag-menu-option {
                display: flex !important;
                align-items: center !important;
                height: 36px !important;
                line-height: 36px !important;
                padding-left: 0px !important;
                padding-right: 0px !important;
                cursor: pointer;
                .ag-menu-option-icon {
                    padding-right: 0px !important;
                    padding-left: 0px !important;
                    width: 37px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    span {
                        margin-top: unset !important;
                    }
                }
            }
        }
    }

    .grid-dock-top {
        overflow: auto;
        .grid-dock-top-left {
            flex: auto;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            min-width: max-content;
        }
        .grid-dock-top-right {
            flex: auto;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-end;
            min-width: max-content;
        }
    }

    .ag-menu-separator {
        & > span {
            height: 4px !important;
        }
    }

    .grid-btn-view-file {
        color: #1890ff;
        border: 1px solid #1890ff;
        padding: 3px 8px;
        border-radius: 3px;
        cursor: pointer;
        min-width: 85px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;

        i,
        .anticon {
            display: inline-flex;
        }

        &:hover {
            background: #1890ff;
            color: #fff !important;
        }
    }

    .ag-cell-wrapper {
        display: flex;
        align-items: center;
    }

    .ag-full-width-row {
        border-bottom: 1px solid $boder-color-grid !important;
        > span {
            display: flex;
            align-items: center;
            .ag-group-contracted {
                display: inline-block;
            }
            .ag-group-expanded {
                display: inline-block;
                align-items: unset !important;
            }
        }
    }

    .ag-horizontal-right-spacer {
        border-color: $boder-color-grid !important;
    }

    .id-cell-full-width {
        right: 0px;
        z-index: 1 !important;
        width: auto;
        background: #fff;
        &.id-cell-full-width-odd {
            background-color: $ag-row-odd-bg;
        }
    }

    .id-grid-cell-renderer {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    &.grid-auto-width {
        .ag-root.ag-layout-normal {
            width: max-content !important;
            flex: unset !important;
            max-width: 100% !important;
        }

        .ag-root-wrapper,
        .wrap-grid {
            width: fit-content;
        }
    }

    .ag-cell {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        &.ag-cell-changed {
            color: #faad14 !important;
        }

        &.id-cell-editable {
            .ag-group-child-count {
                margin-right: 3px;
            }
            .id-grid-cell-icon-edit {
                background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTIxLjQ4IDEyMi44OCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIxLjQ4IDEyMi44OCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlIHR5cGU9InRleHQvY3NzIj4uc3Qwe2ZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkO308L3N0eWxlPjxnPjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik05Ni44NCwyLjIybDIyLjQyLDIyLjQyYzIuOTYsMi45NiwyLjk2LDcuOCwwLDEwLjc2bC0xMi40LDEyLjRMNzMuNjgsMTQuNjJsMTIuNC0xMi40IEM4OS4wNC0wLjc0LDkzLjg4LTAuNzQsOTYuODQsMi4yMkw5Ni44NCwyLjIyeiBNNzAuMTgsNTIuMTlMNzAuMTgsNTIuMTlsMCwwLjAxYzAuOTIsMC45MiwxLjM4LDIuMTQsMS4zOCwzLjM0IGMwLDEuMi0wLjQ2LDIuNDEtMS4zOCwzLjM0djAuMDFsLTAuMDEsMC4wMUw0MC4wOSw4OC45OWwwLDBoLTAuMDFjLTAuMjYsMC4yNi0wLjU1LDAuNDgtMC44NCwwLjY3aC0wLjAxIGMtMC4zLDAuMTktMC42MSwwLjM0LTAuOTMsMC40NWMtMS42NiwwLjU4LTMuNTksMC4yLTQuOTEtMS4xMmgtMC4wMWwwLDB2LTAuMDFjLTAuMjYtMC4yNi0wLjQ4LTAuNTUtMC42Ny0wLjg0di0wLjAxIGMtMC4xOS0wLjMtMC4zNC0wLjYxLTAuNDUtMC45M2MtMC41OC0xLjY2LTAuMi0zLjU5LDEuMTEtNC45MXYtMC4wMWwzMC4wOS0zMC4wOWwwLDBoMC4wMWMwLjkyLTAuOTIsMi4xNC0xLjM4LDMuMzQtMS4zOCBjMS4yLDAsMi40MSwwLjQ2LDMuMzQsMS4zOEw3MC4xOCw1Mi4xOUw3MC4xOCw1Mi4xOUw3MC4xOCw1Mi4xOXogTTQ1LjQ4LDEwOS4xMWMtOC45OCwyLjc4LTE3Ljk1LDUuNTUtMjYuOTMsOC4zMyBDLTIuNTUsMTIzLjk3LTIuNDYsMTI4LjMyLDMuMywxMDhsOS4wNy0zMnYwbC0wLjAzLTAuMDNMNjcuNCwyMC45bDMzLjE4LDMzLjE4bC01NS4wNyw1NS4wN0w0NS40OCwxMDkuMTFMNDUuNDgsMTA5LjExeiBNMTguMDMsODEuNjZsMjEuNzksMjEuNzljLTUuOSwxLjgyLTExLjgsMy42NC0xNy42OSw1LjQ1Yy0xMy44Niw0LjI3LTEzLjgsNy4xMy0xMC4wMy02LjIyTDE4LjAzLDgxLjY2TDE4LjAzLDgxLjY2eiIvPjwvZz48L3N2Zz4=);
                content: "";
                display: none;
                opacity: 0;
                right: 0px;
                position: absolute;
                background-color: transparent;
                z-index: 999;
                right: 2px;
                top: 2px;
                height: 10px;
                width: 10px;
                background-repeat: no-repeat;
                background-size: 9px;
                background-position: center;
                transition: all 0.1s;
            }

            &.ag-cell-not-inline-editing {
                &:hover {
                    .id-grid-cell-icon-edit {
                        display: block;
                        opacity: 1;
                    }
                }
            }
        }
    }

    &.grid-has-focus {
        .ag-has-focus {
            .ag-cell-focus {
                .ag-cell-edit-input {
                    border: 0px !important;
                }
                &::before {
                    position: absolute;
                    content: "";
                    border-right: 1px solid $boder-color-grid-focus;
                    background: #fff;
                    display: block;
                    width: 1px;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    z-index: 9;
                }
                &::after {
                    position: absolute;
                    content: "";
                    border-top: 1px solid $boder-color-grid-focus;
                    background: #fff;
                    display: block;
                    height: 1px;
                    top: 0;
                    right: 0;
                    left: 0;
                    z-index: 9;
                }
            }
        }
    }

    .id-grid-cell-note {
        position: absolute;
        cursor: pointer;
        z-index: 99;
        &.id-grid-cell-comment,
        &.id-grid-cell-warning {
            top: 5px;
            right: 5px;
        }
        &.id-grid-cell-error {
            top: 0px;
            right: 0px;
            .triangle {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                border-color: transparent;
                border-style: solid;
                border-width: 4px;
                border-right-color: red;
                border-top-color: red;
                cursor: pointer;
            }
        }
    }

    .number-count-child {
        margin-left: 3px;
        margin-top: -2px;
        font-size: 12px;
        font-style: italic;
        position: absolute;
        right: 0px;
        top: 0px;
    }

    .ag-group-child-count {
        margin-left: 3px;
        margin-top: -2px;
        font-size: 11px;
        font-style: italic;
    }

    &.grid-auto-height-header {
        .ag-header-cell-text,
        .ag-group-cell-text {
            white-space: normal !important ;
            line-height: 16px !important;
            overflow: visible !important;
            padding: 5px 0px;
            height: auto !important;
        }
    }

    .ag-cell-value-text {
        .id-cell-check {
            display: flex;
            align-items: center;
            height: 100%;
            justify-content: center;
        }
        .id-cell-wrap-tag {
            display: flex;
            align-items: center;
            .id-cell-tag {
                background: #fafafa;
                border: 1px solid #d9d9d9;
                border-radius: 3px;
                margin: 2.5px;
                max-width: 50px;
                height: 24px;
                line-height: 22px;
                padding: 0px 6px;
                display: inline-block;
                align-items: center;
                justify-content: center;
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
            }
        }

        &:has(a.id-cell-email),
        &:has(a.id-cell-url),
        &:has(a.id-cell-phone),
        &:has(.id-grid-cell-format-status),
        &:has(.id-cell-check) {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        a.id-cell-email,
        a.id-cell-url,
        a.id-cell-phone {
            color: var(--main-font-color, #333);
            display: inline-block;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
            width: 100%;

            .anticon {
                vertical-align: -2px !important;
            }
            &:hover {
                cursor: pointer;
                color: #1890ff;
            }
        }

        .id-grid-cell-format-status {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .id-grid-cell-format-status-content {
                height: 22px;
                line-height: 20px;
                border-radius: 3px;
                font-size: 12px;
                font-weight: normal;
                text-align: center;
                padding: 0px 5px;
                display: inline-block !important;
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
            }
        }

        .three-dot {
            display: inline-block !important;
            width: 100%;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
        }

        &:has(.ant-progress) {
            width: 100% !important;
        }
    }

    &.grid-not-auto-height-row {
        .ag-cell-value-text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        // .ag-row-group-leaf-indent,
        .ag-cell-wrapper {
            .ag-group-value {
                display: inline-block;
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
            }
        }

        .group-tree-col > span {
            width: 100%;
            .ag-group-value {
                display: inline-block;
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
            }
        }

        .group-tree-col {
            .ag-cell-wrapper {
                > span.ag-cell-value {
                    width: 100%;
                }
            }
            // &.group-tree-col-without-children {
            //     .ag-row-group-leaf-indent {
            //         margin-left: 16px !important;
            //     }
            // }
        }
    }
    .ag-cell-value-text-list {
        padding: 10px !important;
    }

    &.grid-hidden-icon-expanded {
        .ag-group-expanded,
        .ag-group-contracted {
            display: none !important;
        }

        &.grid-has-checkbox-selection,
        &.grid-not-has-checkbox {
            // .ag-row-group-leaf-indent,
            .ag-group-checkbox {
                margin-left: 0px !important;
            }
        }

        &.grid-tree-line {
            // .ag-row-group-leaf-indent,
            .ag-group-checkbox {
                margin-left: 28px !important;
            }

            // &.grid-not-has-checkbox {
            //     .id-row-group-indent-vertical {
            //         right: 18px !important;
            //     }

            //     .id-row-group-indent-horizotal {
            //         left: -18px !important;
            //         width: 18px !important;
            //     }

            //     .ag-row-group-expanded {
            //         .id-row-group-indent-vertical {
            //             right: -10px !important;
            //         }
            //         .id-row-group-indent-horizotal {
            //             left: 10px !important;
            //         }
            //     }
            // }

            &.grid-has-checkbox-selection {
                .ag-group-checkbox {
                    margin-left: 0px !important;
                }
                // .id-row-group-indent-vertical {
                //     right: 18px !important;
                // }
                // .id-row-group-indent-horizotal {
                //     left: -18px !important;
                // }

                // .ag-row-group-expanded {
                //     .id-row-group-indent-vertical {
                //         right: -10px !important;
                //     }
                //     .id-row-group-indent-horizotal {
                //         left: 10px !important;
                //     }
                // }
            }

            // span.id-row-group-indent-last {
            //     margin-right: 0px !important;
            // }

            // .id-row-group-indent-vertical {
            //     right: 10px !important;
            // }
            // .id-row-group-indent-horizotal {
            //     left: -10px !important;
            //     width: 18px !important;
            // }

            // .ag-row-group-expanded {
            //     .id-row-group-indent-vertical {
            //         right: -18px !important;
            //     }
            //     .id-row-group-indent-horizotal {
            //         left: 18px !important;
            //     }
            // }
            // .ag-row-level-0 {
            //     .ag-row-group-leaf-indent {
            //         margin-left: 0px !important;
            //     }
            // }
        }
    }

    &.grid-tree-line {
        &.grid-not-has-checkbox {
            .ag-group-checkbox {
                margin-left: 0px !important;
            }
        }

        .ag-group-expanded,
        .ag-group-contracted {
            display: flex;
            align-items: center;
        }

        // span.ag-row-group-leaf-indent {
        //     &.id-row-group-indent-row-span {
        //         height: 100%;
        //         .ag-group-value {
        //             height: 100%;
        //             display: flex;
        //             align-items: center;
        //             .ag-react-container {
        //                 height: 100%;
        //                 display: flex;
        //                 align-items: center;
        //             }
        //         }
        //     }
        // }

        // &.grid-not-auto-height-row {
        //     span.ag-row-group-leaf-indent {
        //         width: calc(100% - 20px);
        //     }
        // }

        // span.id-row-group-indent-last {
        //     margin-right: 8px !important;
        // }

        // .ag-row-group-contracted,
        // .ag-row-group-expanded {
        //     span.id-row-group-indent-last {
        //         margin-right: 20px !important;
        //     }

        //     .id-row-group-indent-vertical {
        //         right: -8px !important;
        //     }
        //     .id-row-group-indent-horizotal {
        //         left: 7px !important;
        //         width: 12px !important;
        //     }
        // }

        // .id-row-group-indent {
        //     display: inline-block;
        //     margin-right: 20px;
        //     .id-row-group-indent-children {
        //         position: relative;
        //         margin-top: -12px;
        //         margin-bottom: -12px;
        //         display: flex;
        //         align-items: center;
        //         .id-row-group-indent-vertical {
        //             position: absolute;
        //             top: 0;
        //             right: 20px;
        //             bottom: 0px;
        //             border-right: 1px dashed var(--tree-line-color, #aab2bd);
        //             content: "";
        //         }
        //         .id-row-group-indent-horizotal {
        //             border-bottom: 1px dashed var(--tree-line-color, #aab2bd);
        //             height: 1px;
        //             width: 24px;
        //             left: -20px;
        //             position: absolute;
        //             top: 50%;
        //         }
        //     }
        // }
    }

    .ag-row-group {
        display: flex;
        align-items: center;
        &.ag-full-width-row {
            padding-left: 5px;
            .ag-group-checkbox {
                &.ag-invisible {
                    margin-left: 3px;
                }
            }
        }
    }

    .ag-pinned-right-header {
        border-color: $boder-color-grid !important;
        .ag-header-group-cell,
        .ag-header-cell {
            border-right: 1px solid $boder-color-grid;
        }
    }

    .ag-floating-filter-full-body {
        width: calc(100% + 12px);
        overflow: hidden;
        margin-left: -6px;
        margin-right: -6px;
    }

    .ag-row-drag {
        display: flex;
        align-items: center;

        .ag-icon-row-drag {
            opacity: 1;
            background-position-y: 2px;
            width: 28px;
        }
    }

    .horizotal-line {
        position: absolute;
        left: -1px;
        bottom: -1px;
        right: -1px;
        height: 1px;
        background: #fff;
        display: flex;

        .horizotal-line-left {
            background: transparent;
        }

        .horizotal-line-middle {
            flex: 1;
            background: $boder-color-grid;
        }

        .horizotal-line-right {
            background: transparent;
        }
    }

    .vertical-line {
        position: absolute;
        height: calc(100% - 20px);
        background: $boder-color-grid;
        width: 1px !important;
        left: -1px;
    }

    &.min-height-grid {
        .wrap-grid {
            display: flex;
            flex-direction: column;
        }

        .wrap-grid > div {
            display: flex;
            flex-direction: column;
            flex: 1;
        }

        .ag-root-wrapper,
        .ag-root-wrapper-body {
            flex: 1;
        }
    }

    .ag-row-selected {
        background-color: $ag-row-selected-bg !important;
    }

    .ag-center-cols-clipper,
    .ag-pinned-left-cols-container,
    .ag-pinned-right-cols-container {
        min-height: 100% !important;
    }

    .ag-row-position-absolute {
        border-color: $boder-color-grid;
        border-right: 0px solid $boder-color-grid !important;
    }

    .ag-group-expanded,
    .ag-group-contracted {
        cursor: pointer;
    }

    .ag-cell {
        &:has(.id-grid-cell-create) {
            padding: 0px !important;
        }
    }

    .ag-react-container {
        height: 100%;
        width: 100%;
        .id-cell-rendertooltip {
            display: flex;
            height: 100%;
            width: 100%;
            align-items: center;
        }
        &:has(.id-grid-cell-create) {
            height: unset !important;
            .id-grid-cell-create {
                height: 100%;
                width: 100%;
            }
        }
    }

    .auto-group-class {
        border-left: 0px !important;
    }

    .ag-center-cols-viewport {
        // background: #ffffff;

        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar:horizontal {
            height: 0px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 14px;
            border: 2px solid white;
            background-color: #c1c1c1;
        }

        &::-webkit-scrollbar-track {
            background-color: #fff;
            border-radius: 14px;
        }
    }

    &.auto-height-grid {
        .ag-row.ag-row-last:not(.ag-row-first) {
            border-bottom-width: 0px !important;
            .ag-cell {
                border-bottom-width: 0px !important;
            }
        }

        .ag-center-cols-viewport {
            overflow-y: hidden !important;
        }
    }

    .ag-side-bar {
        border-color: $boder-color-grid !important;
        border: 0px solid $boder-color-grid !important;
    }

    .ag-status-bar {
        border: 0px !important;
    }

    .ag-root {
        border: 0px solid $boder-color-grid;
    }

    &.grid-border-left {
        .ag-root {
            border-left: 1px solid $boder-color-grid !important;
        }
    }

    &.grid-border-right {
        .ag-root {
            border-right: 1px solid $boder-color-grid !important;
        }
    }

    &.grid-border-bottom {
        .ag-root {
            // &:has(.ag-body-horizontal-scroll) {
            //     border-bottom: 0px solid $boder-color-grid !important;
            // }
            border-bottom: 1px solid $boder-color-grid !important;
            border-collapse: collapse;
        }
    }

    &.grid-border-top {
        .ag-root {
            border-top: 1px solid $boder-color-grid !important;
        }
    }

    .ag-cell-focus {
        border: 0px !important;
    }

    .ag-row-hover {
        .id-cell-full-width {
            background: #ecf0f1;
        }
    }

    &.id-grid {
        .ag-row-odd {
            background-color: $ag-row-odd-bg;
        }

        .ag-group-value {
            margin-left: 0px !important;
        }

        .ag-group-checkbox {
            margin-left: 0px !important;
            display: flex;
            align-items: center;
            .ag-selection-checkbox {
                margin-right: 5px !important;
                &.ag-hidden {
                    margin-right: 0px !important;
                }
            }
        }
    }

    .border-left-none {
        border-left: 0px !important;
    }

    .ag-selection-checkbox {
        cursor: pointer;
        display: flex;

        span {
            top: 0px !important;
        }
    }

    .ag-cell-inline-editing {
        height: 100%;
        border-left: 1px solid $boder-color-grid !important;
        padding: 0px;

        &:has(.cell-grid-editable-select) {
            height: unset !important;
            min-height: 100% !important;
        }

        .ag-react-container {
            height: 100%;
            &:has(.cell-grid-editable-select) {
                height: unset !important;
                min-height: 100% !important;
            }
        }
        .ag-input-text-wrapper {
            width: 100%;
            height: 100%;
            max-height: 50px;
        }

        .cell-grid-center {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .body-wrap,
            input {
                border: 0px !important;
            }

            // input {
            //     height: 100%;
            //     width: 100%;
            //     padding: 0px 10px;
            //     border: 1px solid #0088fe !important;
            // }
        }

        &.group-tree-col {
            padding: 0px !important;
        }
    }

    .group-tree-col {
        padding: 10px;
        > span {
            display: flex;
            line-height: 16px;
        }

        > span.ag-cell-wrapper {
            align-items: center;
            > span.ag-cell-value > span {
                display: flex;
                line-height: 16px;
            }
        }
    }

    .ag-cell {
        display: flex;
        align-items: center;

        &.cell-wrap-text {
            white-space: normal !important;
            line-height: 20px !important;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-top: 5px;
            padding-bottom: 5px;

            .ag-group-checkbox {
                display: flex;
                align-items: center;
            }
        }

        &.ag-cell-inline-editing {
            &.cell-wrap-text {
                display: flex !important;
                padding: 0px;
            }
        }

        &.ag-cell-has-inline-editing {
            display: inline-flex !important;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;

            &.cell-wrap-text,
            &:has(.id-text-three-dot) {
                display: flex !important;
            }
        }

        &.text-align-center {
            justify-content: center;
            text-align: center;
        }
        &.text-align-left {
            justify-content: flex-start;
            text-align: left;
        }
        &.text-align-right {
            justify-content: flex-end;
            text-align: right;
        }

        .ant-progress-line {
            display: flex;
            align-items: center;
            .ant-progress-text {
                font-size: 12px;
                width: fit-content !important;
            }
        }
    }

    // Header Css
    .ag-header {
        border-color: $boder-color-grid !important;
        border-bottom: 0px;

        .header-number-row {
            padding: 0px;
            border-left: 0px !important;

            .ag-header-cell-label {
                width: 100%;
            }
        }

        .ag-header-cell-label {
            align-items: center;
            justify-content: center;
        }

        .ag-header-group-text {
            font-weight: bold;
            color: #333;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                display: flex;
                align-items: center;
            }
        }

        .ag-header-row {
            border-color: $boder-color-grid !important;
            border-right: 0px solid $boder-color-grid !important;
        }

        .ag-header-group-text,
        .ag-header-cell-text {
            line-height: 16px;
            padding-top: 5px;
            padding-bottom: 5px;
            font-weight: bold;
            color: #333;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &.ag-header-cell-wrap-text {
                white-space: break-spaces;
            }
        }

        .ag-header-icon {
            &.ag-sort-order {
                display: none;
            }
            &.ag-sort-ascending-icon,
            &.ag-sort-descending-icon {
                display: flex;
                align-items: center;
            }
        }

        .header-action {
            border-color: $boder-color-grid;
            border-left: 1px solid $boder-color-grid;
            .custom-action-header {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #333;
                overflow: hidden;
                position: absolute;
                left: 0px;
                top: -32px;
                z-index: 9;
            }
        }

        .header-align-left {
            display: flex;
            justify-content: flex-start;

            .ag-header-cell-label {
                justify-content: flex-start !important;
            }
        }

        .header-align-right {
            display: flex;
            justify-content: flex-end;
            .ag-header-cell-text {
                text-align: right;
            }
        }

        .header-align-center {
            display: flex;
            justify-content: center;
            .ag-header-cell-text {
                text-align: center;
            }
        }

        .ag-header-group-cell-label {
            justify-content: center;
        }

        .header-height-grid {
            .ag-header-cell-label {
                width: 100%;
            }

            .ag-header-cell-text {
                white-space: normal;
                line-height: 16px;
                text-align: center;
                display: flex;
                align-items: center;

                &.ag-header-cell-wrap-text {
                    white-space: break-spaces;
                }
            }
        }

        .header-action,
        .header-align-left {
            .header-border-right {
                display: none;
            }
        }

        .ag-header-viewport {
            .ag-header-container {
                border-right: 0px solid $boder-color-grid;
            }
        }

        .ag-pinned-left-header {
            border-color: $boder-color-grid;
            border-right: 0px solid $boder-color-grid !important;
        }

        // .ag-header-select-all {
        //     margin-left: 5px;
        // }
    }

    // Lines Grid

    .ag-cell-last-left-pinned,
    .ag-cell,
    .ag-cell-focus,
    .ag-row:not(.ag-row-first) {
        border-top: 0 !important;
        border-left: 0 !important;
        border-right: 0 !important;
        border-bottom: 0;
    }

    .ag-header-cell,
    .ag-header-group-cell {
        border: 0;

        .ag-header-cell-resize {
            z-index: 99;
        }

        &::after {
            display: none;
        }
    }
    &.grid-auto-height-row {
        .group-tree-col {
            .ag-cell-wrapper {
                > span.ag-cell-value {
                    width: 100%;
                }
            }
            // &.group-tree-col-without-children {
            //     .ag-row-group-leaf-indent {
            //         margin-left: 16px !important;
            //     }
            // }
        }
    }

    &.grid-column-lines {
        .ag-cell {
            border-left-width: 1px !important;
            border-left-style: solid !important;
            border-left-color: $boder-color-grid !important;
            &.border-left-color-transparent {
                border-left-color: transparent !important;
                border-left-width: 0px !important;
            }
        }

        .ag-cell-last-left-pinned {
            border-right: 1px solid $boder-color-grid !important;
        }

        &.grid-has-focus {
            .ag-has-focus {
                .ag-cell-focus {
                    border-bottom-color: var(--main-color) !important;
                    border-left-color: var(--main-color) !important;
                }
            }
        }

        &.grid-range-seletion {
            .ag-cell {
                &.border-left-color-transparent {
                    border-left-color: transparent !important;
                    border-left-width: 1px !important;
                }
                &.ag-cell-focus {
                    border-left-color: $boder-color-grid-focus !important;
                }
            }
        }
    }

    &.grid-row-lines {
        .ag-row:not(.ag-row-first) {
            border-width: 1px 0 0;
        }

        .ag-cell {
            border-bottom-width: 1px !important;
            border-bottom-style: solid !important;
            border-bottom-color: $boder-color-grid !important;
        }

        &.grid-type-line-dashed {
            .ag-cell,
            .ag-cell-focus {
                border-style: dashed !important;
            }
        }

        &.grid-type-line-dotted {
            .ag-cell,
            .ag-cell-focus {
                border-style: dotted !important;
            }
        }

        &.grid-range-seletion {
            .ag-cell {
                &.ag-cell-focus {
                    border-bottom-color: $boder-color-grid-focus !important;
                }
            }
        }
    }

    &.grid-header-background-transparent {
        .ag-header,
        .custom-header,
        .group-header-custom {
            background-color: transparent !important;
        }
    }

    &.grid-header-vertical-lines {
        .header-border-right {
            width: 1px !important;
        }

        .ag-header-cell {
            border-left: 1px solid $boder-color-grid;

            &.none-border-left {
                border-left: 0px !important;
            }

            &:first-child {
                border-left: 0px solid $boder-color-grid !important;
            }

            &::after {
                border-right: 0px solid $boder-color-grid;
                content: " ";
                height: 100%;
                margin-top: 0px;
                position: absolute;
                right: 0px;
                text-indent: -2000px;
                top: 0;
            }
        }

        .ag-header-group-cell {
            border-left: 1px solid $boder-color-grid !important;

            &.ag-header-group-cell-no-group {
                border-bottom: 0px !important;
            }

            &:first-child {
                border-left: 1px solid $boder-color-grid !important;
            }

            &::after {
                border-right: 0px solid $boder-color-grid;
                content: " ";
                height: 100%;
                margin-top: 0px;
                position: absolute;
                right: 0px;
                text-indent: -2000px;
                top: 0;
            }

            &:last-child {
                border-right: 1px solid $boder-color-grid;

                &::after {
                    height: 0px;
                }
            }
        }

        .ag-header-viewport {
            .ag-header-container {
                border-right: 1px solid $boder-color-grid;
            }
        }

        .ag-pinned-left-header {
            border-right: 1px solid $boder-color-grid !important;
        }
    }

    &.grid-header-horizotal-lines {
        .ag-header-cell {
            border-bottom: 1px solid $boder-color-grid;

            &.none-border-left {
                border-left: 0px !important;
            }

            &:first-child {
                border-left: 0px solid $boder-color-grid !important;
            }

            &::after {
                border-right: 0px solid $boder-color-grid;
                content: " ";
                height: 100%;
                margin-top: 0px;
                position: absolute;
                right: 0px;
                text-indent: -2000px;
                top: 0;
            }
        }

        .ag-header-group-cell {
            border-bottom: 0px solid $boder-color-grid !important;
            .group-header-custom {
                border-bottom: 1px solid $boder-color-grid !important;
            }

            &.ag-header-group-cell-no-group {
                border-bottom: 0px !important;
            }

            &:first-child {
                border-left: 0px solid $boder-color-grid !important;
            }

            &::after {
                border-right: 0px solid $boder-color-grid;
                content: " ";
                height: 100%;
                margin-top: 0px;
                position: absolute;
                right: 0px;
                text-indent: -2000px;
                top: 0;
            }

            &:last-child {
                border-right: 0px solid $boder-color-grid;

                &::after {
                    height: 0px;
                }
            }
        }

        .ag-header-viewport {
            .ag-header-container {
                border-right: 0px solid $boder-color-grid;
            }
        }

        .ag-pinned-left-header {
            border-right: 1px solid $boder-color-grid !important;
        }
    }

    .group-header-custom {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 1px;
        top: 0px;
        text-align: center;
        font-weight: bold;
        color: #333;
        background: $header-background-grid;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 5px;
        z-index: 0;

        > span {
            display: inline-block;
            width: 100%;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
            text-align: center;
        }

        .ag-header-icon {
            cursor: pointer;
            padding-bottom: 3px;
        }
    }

    .grid-header-pivot-custom {
        position: absolute;
        left: 1px;
        right: 1px;
        bottom: 1px;
        top: 0px;
        text-align: center;
        font-weight: bold;
        color: #333;
        background: $header-background-grid;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;

        span {
            display: inline-block;
            width: 100%;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
        }
    }

    .overflow-visible {
        overflow: visible;
    }

    .custom-header {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 1px;
        right: 1px;
        bottom: 1px;
        padding-left: 5px;
        padding-right: 5px;
        z-index: 9;

        &.custom-header-left {
            justify-content: flex-start;
            flex-direction: row;
        }

        &.custom-header-right {
            justify-content: flex-end;
            flex-direction: row;
        }

        .header-border-right {
            position: absolute;
            top: 0px;
            right: -2px;
            bottom: 0px;
            height: 100%;
            background: $boder-color-grid !important;
        }

        &.header-action {
            border-left: 0px !important;
            right: 0px;
        }
    }

    .check-box-selection {
        display: flex;
        align-items: center;
        justify-content: center;

        .ag-selection-checkbox {
            display: flex;
        }
    }

    .grid-cell {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }
}

.button-action-bar {
    height: 32px;
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;
    border-radius: 5px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background: #0088fe;
        border-color: #0088fe;

        color: #fff;
        i,
        span,
        .anticon {
            color: #fff;
        }
    }
    &.btn-fullscreen,
    &.ant-btn.btn-refresh,
    &.ant-btn.btn-export,
    &.ant-btn.btn-delete,
    &.ant-btn.btn-approval,
    &.ant-btn.btn-reject,
    &.ant-btn.btn-import {
        box-shadow: none !important;
        transition: unset !important;

        i,
        .anticon {
            font-size: 18px;
            color: #333;
            margin-right: 0px;

            display: flex;
            align-items: center;
            justify-content: center;
        }

        &:hover {
            i,
            .anticon,
            span {
                color: #fff;
            }
        }

        &.btn-mass-delete {
            background: red;
            color: #fff;
            .anticon {
                color: #fff;
            }
            &:hover {
                border-color: red;
                background: transparent;
                color: red;
                .anticon {
                    color: red;
                }
            }
        }
        &.btn-mass-approval {
            background: #2dce89;
            color: #fff;
            .anticon {
                color: #fff;
            }
            &:hover {
                border-color: #2dce89;
                background: transparent;
                color: #2dce89;
                .anticon {
                    color: #2dce89;
                }
            }
        }
        &.btn-mass-reject {
            background: #f5365c;
            color: #fff;
            .anticon {
                color: #fff;
            }
            &:hover {
                border-color: #f5365c;
                background: transparent;
                color: #f5365c;
                .anticon {
                    color: #f5365c;
                }
            }
        }
    }

    &.btn-create {
        background: #0088fe;
        color: #fff;
        .anticon {
            color: #fff;
        }
        &:hover {
            border-color: #0088fe;
            background: transparent;
            color: #0088fe !important;
            .anticon {
                color: #0088fe !important;
            }
        }
    }
}

li.rc-select-dropdown-menu-item {
    padding: 5px 8px;
    font-size: 13px;
}

.rc-select-dropdown-placement-bottomLeft::before {
    position: absolute;
    top: -5px;
    left: 5px;
    content: "";
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
}

.rc-select-dropdown-placement-topLeft::before {
    position: absolute;
    bottom: -5px;
    left: 5px;
    content: "";
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #fff;
}

li.rc-select-dropdown-menu-item-active {
    background-color: #0088fe !important;
}

.rc-select-selection:hover {
    border-color: #ccc !important;
    box-shadow: unset !important;
}

.button-full-screen {
    margin: 5px;
    height: 32px;
    width: 32px;
    border-radius: 3px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    .anticon {
        vertical-align: 0px !important;
    }
}

.FullScreen {
    background: #fff;
}

.bg-opacity {
    background: #fff !important;
}

.empty-text {
    overflow: hidden;
    font-size: 13px;
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 2;
}

.fullscreen-guide {
    position: fixed;
    top: 10px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
    pointer-events: none;

    .fullscreen-guide-body {
        display: flex;
        align-items: center;
        font-size: 14px;
        text-transform: none;
        padding: 5px 10px;
        color: #fff;
        background: rgba(0, 0, 0, 0.75);
        pointer-events: none;
        border-radius: 3px;

        .esc {
            border: 1px solid rgb(255, 255, 255);
            display: block;
            border-radius: 3px;
            margin: 0px 10px;
            padding: 0px 5px;
            height: 30px;
            line-height: 30px;
        }
    }
}

.triangle-top-right {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 4px;
    border-right-color: #0c0;
    border-top-color: #0c0;
    cursor: pointer;
}

.grid-cell-error {
    span,
    div {
        color: red;
    }
}

// Context Menu Column
.ag-root-wrapper {
    overflow: visible !important;

    .ag-menu {
        max-height: unset !important;

        .ag-primary-cols-header-panel {
            display: flex;
            align-items: center;

            .ag-icon-tree-open,
            .ag-icon-tree-closed {
                margin-top: 5px;
            }

            .ag-icon-checkbox-checked,
            .ag-icon-checkbox-unchecked {
                margin-top: 5px;
                // display: none;
            }
        }
    }

    .ag-primary-cols-filter {
        border: 1px solid #ccc;
    }
}

.ag-header-cell-menu-button {
    opacity: 1 !important;
    transition:
        opacity 0.2s ease 0s,
        border 0.2s ease 0s;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 5px;
}

.ag-cell-label-container {
    display: flex;
    flex-direction: row-reverse;

    .ag-header-cell-label {
        flex: 1;
    }
}

.grid-global-filter {
    border-radius: 5px;
    width: 220px;
    display: flex;
}

.has-filter {
    .ag-filter-icon {
        display: flex !important;
    }
}

.button-action-bar-group {
    background: rgb(0, 123, 255);
    color: rgb(255, 255, 255);
    border: 0px;
    display: flex;
    align-items: center;
    padding: 0px 8px;
    height: 32px;
    .anticon {
        margin-right: 5px;
    }
}

.sub-menu {
    .ant-popover-inner {
        min-width: unset !important;
    }

    .ant-popover-inner-content {
        padding: 0px !important;
        border-radius: 3px;

        .menu-item {
            .menu-item-button {
                display: flex;
                align-items: center;
                color: rgb(51, 51, 51);
                line-height: 22px;
            }

            i,
            .anticon {
                margin-right: 5px !important;
                color: rgb(0, 123, 255);
                font-size: 16px;
            }

            &:hover {
                .anticon {
                    color: #fff !important;
                }
            }

            &:first-child {
                button {
                    border-top-right-radius: 3px;
                    border-top-left-radius: 3px;
                }
            }

            &:last-child {
                button {
                    border-bottom-right-radius: 3px;
                    border-bottom-left-radius: 3px;
                }
            }

            button {
                padding: 6px 10px;
                width: 100%;
                border: 0px;
                text-align: left;
                min-width: 115px;

                &:hover {
                    background: #007bff;
                    color: #fff !important;
                }
            }
        }
    }
}

.hidden-header-col {
    display: none;
}

.idtek-grid-loading {
    right: 1px !important;
    bottom: 1px !important;
    left: 1px !important;
    z-index: 3 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ag-cell-three-dot {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
    text-align: left;
    position: relative;
    line-height: 16px;
}

.ag-group-cell-text {
    display: inline-block !important;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    line-height: 22px;
    padding-left: 10px;
}

.tooltip-cell-grid {
    .ant-tooltip-inner,
    .ant-tooltip-arrow-content {
        background-color: var(--main-color);
        --antd-arrow-background-color: var(--main-color);
        color: #fff;
    }
}

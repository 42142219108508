.id-avatar {
    border: 1px solid #fff;
    &.ant-avatar {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
    &.ant-avatar-image {
        background: #ccc;
        .id-avatar-string {
            &.ant-avatar-string {
                transform: scale(1) translateX(-50%);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    &.ant-avatar-square {
        border-radius: 5px;
    }
}

.ant-avatar-group-popover {
    max-width: 300px;
    .ant-popover-inner-content {
        .id-avatar {
            margin-bottom: 2px;
            &:first-child {
                margin-left: 3px;
            }
        }
    }
}

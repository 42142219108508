.cell-grid-editable-time {
    .body-wrap {
        border: 0px !important;
        min-height: unset !important;
        height: 100% !important;
        box-shadow: none !important;
        .ant-picker {
            font-size: var(--main-font-size, 13px);
            height: 100% !important;
            padding: 0px !important;
            input {
                font-size: var(--main-font-size, 13px);
                line-height: 28px;
                padding: 0px 11px !important;
            }
            .ant-picker-clear {
                .icon-remove {
                    height: 100% !important;
                }
            }
        }
    }
}

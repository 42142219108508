.id-drawer {
    .ant-drawer-header {
        padding: 10px;
        padding: 10px;
        background: var(--modal-title-bg, var(--main-color));
        color: var(--modal-title-color, #fff);
        position: relative;
        .ant-drawer-title {
            flex: unset;
            width: 100%;
            color: var(--modal-title-color, #fff);
            display: flex;
            align-items: center;
        }
    }
    .ant-drawer-close {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--modal-title-color, #fff);
        position: absolute;
        top: 0px;
        bottom: 0px;
        right: 0px;
    }

    .id-drawer-loading {
        overflow: hidden;
    }

    &.id-drawer-closable {
        .ant-drawer-header {
            .ant-drawer-title {
                width: calc(100% - 30px);
            }
        }
    }
}

.id-drawer-open {
    overflow: hidden !important;
    position: relative !important;
}

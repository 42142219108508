
.id-grid-skeleton {
    &.id-grid-skeleton-visible {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.25s linear;
    }

    &.id-grid-skeleton-hidden {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s 0.25s, opacity 0.25s linear;
    }
    .ant-skeleton-input {
        min-width: 35px !important;
    }
}
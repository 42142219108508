.cell-grid-editable-select {
    .body-wrap {
        border: 0px !important;
        min-height: unset !important;
        height: 100% !important;
        box-shadow: none !important;
        .ant-select-selector {
            height: 100% !important;
            min-height: unset !important;
            padding-left: 11px !important;
            padding-right: 11px !important;
            .ant-select-selection-item {
                display: flex;
                align-items: center;
                line-height: unset !important;
            }
            input {
                height: 100% !important;
            }
        }
    }
}

.ag-theme-balham {
    &.grid-not-auto-height-row {
        .ag-row-group-leaf-indent {
            .ag-group-value {
                display: inline-block;
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
            }
        }

        .group-tree-col {
            &.group-tree-col-without-children {
                .ag-row-group-leaf-indent {
                    margin-left: 16px !important;
                }
            }
        }
    }

    &.grid-hidden-icon-expanded {
        &.grid-has-checkbox-selection,
        &.grid-not-has-checkbox {
            .ag-row-group-leaf-indent {
                margin-left: 0px !important;
            }
        }

        &.grid-tree-line {
            .ag-row-group-leaf-indent {
                margin-left: 28px !important;
            }

            &.grid-not-has-checkbox {
                .id-row-group-indent-vertical {
                    right: 18px !important;
                }

                .id-row-group-indent-horizotal {
                    left: -18px !important;
                    width: 18px !important;
                }

                .ag-row-group-expanded {
                    .id-row-group-indent-vertical {
                        right: -10px !important;
                    }
                    .id-row-group-indent-horizotal {
                        left: 10px !important;
                    }
                }
            }

            &.grid-has-checkbox-selection {
                .id-row-group-indent-vertical {
                    right: 18px !important;
                }
                .id-row-group-indent-horizotal {
                    left: -18px !important;
                }

                .ag-row-group-expanded {
                    .id-row-group-indent-vertical {
                        right: -10px !important;
                    }
                    .id-row-group-indent-horizotal {
                        left: 10px !important;
                    }
                }
            }

            span.id-row-group-indent-last {
                margin-right: 0px !important;
            }

            .id-row-group-indent-vertical {
                right: 10px !important;
            }
            .id-row-group-indent-horizotal {
                left: -10px !important;
                width: 18px !important;
            }

            .ag-row-group-expanded {
                .id-row-group-indent-vertical {
                    right: -18px !important;
                }
                .id-row-group-indent-horizotal {
                    left: 18px !important;
                }
            }
            .ag-row-level-0 {
                .ag-row-group-leaf-indent {
                    margin-left: 0px !important;
                }
            }
        }
    }

    &.grid-tree-line {
        span.ag-row-group-leaf-indent {
            &.id-row-group-indent-row-span {
                height: 100%;
                .ag-group-value {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    .ag-react-container {
                        height: 100%;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }

        &.grid-not-auto-height-row {
            span.ag-row-group-leaf-indent {
                width: calc(100% - 20px);
            }
        }

        span.id-row-group-indent-last {
            margin-right: 8px !important;
        }

        .ag-row-group-contracted,
        .ag-row-group-expanded {
            span.id-row-group-indent-last {
                margin-right: 20px !important;
            }

            .id-row-group-indent-vertical {
                right: -8px !important;
            }
            .id-row-group-indent-horizotal {
                left: 7px !important;
                width: 12px !important;
            }
        }

        .id-row-group-indent {
            display: inline-block;
            margin-right: 20px;
            .id-row-group-indent-children {
                position: relative;
                margin-top: -12px;
                margin-bottom: -12px;
                display: flex;
                align-items: center;
                .id-row-group-indent-vertical {
                    position: absolute;
                    top: 0;
                    right: 20px;
                    bottom: 0px;
                    border-right: 1px dashed var(--tree-line-color, #aab2bd);
                    content: "";
                }
                .id-row-group-indent-horizotal {
                    border-bottom: 1px dashed var(--tree-line-color, #aab2bd);
                    height: 1px;
                    width: 24px;
                    left: -20px;
                    position: absolute;
                    top: 50%;
                }
            }
        }

        .ag-pinned-left-floating-top {
            .group-tree-col {
                .ag-row-group-leaf-indent {
                    margin-left: 16px !important;
                }
            }
        }
    }

    &.grid-auto-height-row {
        .group-tree-col {
            &.group-tree-col-without-children {
                .ag-row-group-leaf-indent {
                    margin-left: 16px !important;
                }
            }
        }
    }
}

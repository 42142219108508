.ant-notification {
    z-index: 99999 !important;

    .ant-notification-notice {
        width: 350px !important;
    }
}
.id-notification {
    padding: 14px !important;
    * {
        color: #fff !important;
    }
    .ant-notification-notice-icon {
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
    }
    &.notification-success {
        background-color: #51a351 !important;
    }
    &.notification-error {
        background-color: #bd362f !important;
    }
    &.notification-info {
        background-color: #2f96b4 !important;
    }
    &.notification-warning {
        background-color: #f89406 !important;
    }
    &.notification-question {
        background-color: #f89406 !important;
    }
}

.icon-remove {
    display: block;
    width: 30px;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: rgba(255, 0, 0, 0.7);
    font-size: 12px;
    background: #fff;
}
.id-checkbox-filter {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
    .id-checkbox-filter-value {
        width: 25px;
        display: inline-block;
        text-align: center;
        font-size: 15px;
    }
}
.checkbox-filter-picker {
    .ant-popover-inner-content {
        padding: 0px;
        .id-checkbox-filter-content {
            .id-checkbox-filter-content-item {
                width: 150px;
                padding: 5px 10px;
                cursor: pointer;
                align-items: center;
                display: flex;
                &:hover,
                &.id-checkbox-filter-content-item-active {
                    background-color: #bee6f7;
                }
                .id-checkbox-filter-content-item-icon {
                    width: 20px;
                    display: inline-block;
                    font-size: 15px;
                }
            }
        }
    }
}

.ant-popover {
    font-size: var(--main-font-size, 13px);
}

.id-popover-mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1001;
    background: #00000025;
}

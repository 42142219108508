.ant-tabs {
    overflow: hidden;
    &.id-tabs {
        display: flex;
        flex-direction: column;
        // Antd 4.24.0
        .ant-tabs-nav {
            margin: 0px !important;
        }
        .ant-tabs-content-holder {
            flex: 1;
            border-radius: 3px;
            .ant-tabs-content {
                height: 100%;
                .ant-tabs-tabpane {
                    padding: 0px;
                }
            }
        }
        &.id-tabs-body-scroll {
            .ant-tabs-content-holder {
                .ant-tabs-content {
                    .ant-tabs-tabpane {
                        overflow: auto;
                    }
                }
            }
        }
    }
    .ant-tabs-content {
        .ant-tabs-tabpane {
            height: 100%;
        }
    }
}

* {
    &::placeholder {
        color: rgba(0, 0, 0, 0.25) !important;
        font-size: var(--main-font-size, 13px);
        user-select: none;
    }

    &::-webkit-scrollbar {
        border-radius: 0px;
        height: 12px;
        width: 12px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        border: 2px solid white;
        background-color: #c1c1c1;
    }

    &::-webkit-scrollbar-track {
        background-color: #fafafa;
        border-radius: 0px;
    }
}

.placeholder {
    pointer-events: none;
    z-index: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    padding-left: 8px;
    color: rgba(0, 0, 0, 0.25) !important;
    user-select: none;
    display: flex;
    align-items: center;
    line-height: 1;
    font-size: var(--main-font-size, 13px);
    overflow: hidden;
    .placeholder-first {
        display: flex;
        line-height: 1;
        font-size: var(--main-font-size, 13px);
        overflow: hidden;
    }

    .placeholder-second {
        display: flex;
        line-height: 1;
        font-size: var(--main-font-size, 13px);
        text-transform: lowercase;
        overflow: hidden;
    }

    .placeholder-text {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        width: calc(100% - 30px);
        line-height: 1;
        font-size: var(--main-font-size, 13px);
    }
}

.cell-grid-editable-tree-select {
    .body-wrap {
        border: 0px !important;
        min-height: unset !important;
        height: 100% !important;
        box-shadow: none !important;
        .ant-select-selector {
            height: 100% !important;
            min-height: unset !important;
            padding-left: 11px !important;
            padding-right: 11px !important;
            .ant-select-selection-item {
                display: flex !important;
                align-items: center;
                line-height: 28px !important;
            }
            input {
                height: 100% !important;
            }
            &::after {
                line-height: unset !important;
            }
        }

        .ant-select-single {
            display: flex;
            height: 100%;
        }

        &.icon-invisible {
            .ant-select-clear {
                opacity: 1;
                right: 0px !important;
            }
            .ant-select-arrow {
                display: none !important;
            }
            .ant-select-selection-item {
                padding-right: 30px !important;
                display: flex;
            }
        }
    }
}

.ant-btn-primary {
    color: #fff !important;
    border-color: #1890ff !important;
    background: #1890ff !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    font-size: var(--main-font-size, 13px);

    &[disabled] {
        color: rgba(0, 0, 0, 0.25) !important;
        border-color: #d9d9d9 !important;
        background: #f5f5f5 !important;
        text-shadow: none !important;
        box-shadow: none !important;
    }

    &:hover,
    &:focus {
        color: #fff;
        border-color: #40a9ff;
        background: #40a9ff !important;
    }
}

// Tag
.ant-tag {
    color: var(--main-font-color, #333);
    font-size: var(--main-font-size, 13px);
    a {
        color: var(--main-font-color, #333);
        font-size: var(--main-font-size, 13px);
        &:hover {
            color: var(--main-font-color, #333);
            font-size: var(--main-font-size, 13px);
        }
    }
}

$black: #000000;
$white: #ffffff;
.time-icon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 0px;
  margin: 0px;
  outline: 0px;

  &:focus,
  &:active {
    + .time-dropdown {
      transform: translate(0, 20px);
      opacity: 1;
      visibility: visible;
    }
  }

  + .time-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background: $white;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba($black, 0.1);
    text-align: left;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease;
    z-index: 100;

    &:hover,
    &:active,
    &:focus {
      transform: translate(0, 20px);
      opacity: 1;
      visibility: visible;
    }

    &:before {
      content: "";
      position: absolute;
      top: -6px;
      left: 20px;
      width: 0;
      height: 0;
      box-shadow: 2px -2px 6px rgba($black, 0.05);
      border-top: 6px solid $white;
      border-right: 6px solid $white;
      border-bottom: 6px solid transparent;
      border-left: 6px solid transparent;
      transform: rotate(-45deg);
      mix-blend-mode: multiple;
    }

    &.time-dropdown-right {
      &:before {
        content: "";
        position: absolute;
        top: -6px;
        right: 20px !important;
        left: unset !important;
        width: 0;
        height: 0;
        box-shadow: 2px -2px 6px rgba($black, 0.05);
        border-top: 6px solid $white;
        border-right: 6px solid $white;
        border-bottom: 6px solid transparent;
        border-left: 6px solid transparent;
        transform: rotate(-45deg);
        mix-blend-mode: multiple;
      }
    }
  }
}

.disabledTime {
  pointer-events: none;
  opacity: 0.7;
}

.time-picker {
  .ant-picker {
    flex: 1;
    padding-left: 9px;
    .ant-picker-input {
      input {
        font-size: var(--main-font-size, 13px);
        height: calc(var(--main-input-line-height) - 2px) !important;
        line-height: 1;
      }
    }
  }
  .time-icon {
    left: 0;
    right: unset;
  }
  .time-dropdown {
    left: 0;
    right: unset;
    &.time-dropdown-right {
      right: 0;
      left: unset;
    }
  }

  width: 100%;
  border-radius: var(--main-input-border-radius);
  background-color: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;

  .ant-time-picker {
    width: 35px;
    flex: 1;
    display: flex;
    align-items: center;

    .ant-time-picker-input {
      border: none;
      outline: none;
      cursor: default;
      background: transparent;
    }

    .ant-time-picker-icon {
      display: none;
    }
  }
}

.ant-time-picker-panel {
  width: 101px;
  .ant-time-picker-panel-inner {
    border: 1px solid var(--main-input-border-color);
    border-radius: 4px;
    .ant-time-picker-panel-input-wrap {
      padding: 6px 2px 6px 8px;
    }
    .ant-time-picker-panel-combobox {
      box-shadow: 0 0 4px var(--main-input-border-color) !important;
      -moz-box-shadow: 0 0 4px var(--main-input-border-color) !important;
      -webkit-box-shadow: 0 0 4px var(--main-input-border-color) !important;
      position: absolute;
      top: 35px;
      display: flex;

      .ant-time-picker-panel-select {
        width: 49px;
        background: #fff;
        .ant-time-picker-panel-select-option-selected {
          background: #bae7ff;
          color: var(--main-input-border-color);
        }
        &:first-child,
        &:last-child {
          border-color: var(--main-input-border-color) !important;
        }
      }
    }
  }
}

.time-range-picker {
  &.invisible-icon {
    .ant-picker-clear {
      opacity: 1 !important;
      right: 0px !important;
    }
  }
  .ant-picker-range {
    width: 100%;
    padding-right: 0px !important;

    .ant-picker-clear {
      opacity: 1;
      right: 35px;
    }

    .ant-picker-active-bar {
      display: none;
    }
  }

  .id-select-time-range-display {
    display: flex;
    align-items: center;
    color: rgb(24, 144, 255);

    .id-select-time-range-display-icon {
      margin-right: 8px;
    }
    .id-select-time-range-display-value {
      font-size: var(--main-font-size);
      .id-select-time-range-display-separator {
        margin-left: 10px;
        margin-right: 10px;
      }
      .id-select-time-range-display-duration {
        margin-left: 5px;
      }
    }
  }
}

.id-switch {
    min-height: 24px;
    .id-switch-android {
        height: 14px;
        width: 34px;
        min-width: 34px;
        .ant-switch-handle {
            top: -3px;
            left: -3px;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
            &::before {
                border-radius: 50%;
            }
        }
        &.ant-switch-checked {
            background-color: #1976d2;
            .ant-switch-handle {
                left: calc(100% - 20px + 3px);
                &::before {
                    background-color: #1890ff;
                }
            }
        }
    }
}
.id-action-content {
    width: 160px;
    .ant-popover-inner {
        overflow: hidden;
    }

    .ant-popover-inner-content {
        min-width: 160px;
        .ant-menu.ant-menu-inline-collapsed {
            width: 100% !important;
        }
    }
    .ant-popover-inner-content {
        padding: 0px !important;
    }
    .ant-menu {
        border-radius: 3px;

        .ant-menu-item,
        .ant-menu-submenu {
            .id-action-item-title {
                padding-right: 16px;
                font-size: var(--main-font-size, 13px);
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }

        .ant-menu-item,
        .ant-menu-submenu {
            height: auto !important;
            line-height: unset;
            margin: 0px !important;
            padding: 0px !important;
            margin: 0px !important;
            display: flex;
            align-items: center;

            &:hover {
                background-color: #e6f7ff;
            }

            .ant-menu-title-content {
                flex: 1;
                display: flex;
                overflow: hidden;
                .id-action-item {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    .id-action-item-icon {
                        font-size: 14px !important;
                        width: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        overflow: hidden;
                    }
                    .id-action-item-title {
                        flex: 1;
                        display: flex;
                        align-items: center;
                        overflow: hidden;
                    }

                    &.id-action-item-skeleton {
                        padding: 5px 0px;
                        .id-action-item-title {
                            overflow: hidden;
                        }
                        .ant-skeleton-element {
                            display: flex;
                            overflow: hidden;
                        }
                        .ant-skeleton-input {
                            min-width: 110px !important;
                        }
                    }
                }
            }

            &.id-action-none-arrow {
                .ant-menu-submenu-title {
                    padding-right: 0px !important;
                    .ant-menu-submenu-arrow {
                        display: none;
                    }
                }
            }
        }

        .ant-menu-submenu {
            &:hover {
                background-color: #e6f7ff;
            }
            .ant-menu-submenu-title {
                padding-left: 0px !important;
                width: 100%;
                height: auto !important;
                margin: 0px !important;
            }
        }

        .ant-menu-submenu-arrow {
            right: 10px;
            background: transparent;
        }
    }

    &.id-action-panel {
        .ant-menu {
            border: 1px solid #f0f0f0;
        }
    }

    &.id-action-context-menu {
        padding-top: 5px;
        .ant-popover-arrow {
            display: none !important;
        }
    }
    &.id-action-content-none-arrow {
        padding-top: 5px;
    }

    &:not(.id-action-context-menu):not(.id-action-content-none-arrow) {
        &.ant-popover-placement-bottomLeft,
        &.ant-popover-placement-bottomRight,
        &.ant-popover-placement-bottom {
            padding-top: 0px !important;
        }
    }
}

.id-action-sub-content {
    .ant-menu {
        border-radius: 3px;

        .ant-menu-item {
            .id-action-item-title {
                padding-right: 16px;
            }
        }

        .ant-menu-item,
        .ant-menu-submenu {
            height: unset;
            line-height: unset;
            padding: 0px !important;
            margin: 0px !important;
            display: flex;
            align-items: center;

            &:hover {
                background-color: #e6f7ff;
            }

            .ant-menu-title-content {
                flex: 1;
                display: flex;
                overflow: hidden;
                .id-action-item {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    .id-action-item-icon {
                        font-size: 14px !important;
                        width: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        overflow: hidden;
                    }
                    .id-action-item-title {
                        display: flex;
                        align-items: center;
                        flex: 1;
                    }
                }
            }

            &.id-action-none-arrow {
                .ant-menu-submenu-title {
                    padding-right: 0px !important;
                    .ant-menu-submenu-arrow {
                        display: none;
                    }
                }
            }
        }

        .ant-menu-submenu {
            .ant-menu-submenu-title {
                padding-left: 0px !important;
                width: 100%;
                height: auto !important;
                margin: 0px !important;
                &:hover {
                    background-color: #e6f7ff;
                }
            }
        }
        .ant-menu-submenu-arrow {
            right: 10px;
        }
    }
}

.ant-menu-title-content:has(.ant-popover-open) {
    background-color: var(--side-menu-item-active-bg, #bee6f7);
}

.id-action-trigger {
    &.id-action-trigger-hover {
        opacity: 0;
    }
    &:hover,
    &.id-action-trigger-always {
        opacity: 1;
    }
}

.id-action-trigger-container {
    &:hover {
        &:has(.id-action-trigger-hover) {
            .id-action-trigger {
                opacity: 1;
            }
        }
    }
}

.cell-grid-editable {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .body-wrap,
    input {
        border: 0px !important;
        height: 100%;
    }
}

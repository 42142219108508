$menu-item-height: 36px;
.ag-menu {
    min-width: 160px;
    .ant-menu.ant-menu-inline-collapsed {
        width: 100% !important;
    }
    padding: 0px !important;

    .ant-menu-root {
        border-radius: 3px;
        &.ant-menu {
            font-size: var(--main-font-size, 13px);
        }
        &.id-grid-context-menu-overlay {
            .ant-menu-item,
            .ant-menu-submenu {
                height: $menu-item-height !important;
                padding: 0px !important;
                margin: 0px !important;
                display: flex;
                align-items: center;

                &:hover {
                    background-color: #e6f7ff;
                }

                .ant-menu-submenu-title {
                    height: $menu-item-height !important;
                    line-height: $menu-item-height;
                }

                .ant-menu-title-content {
                    flex: 1;
                    display: flex;
                    height: 100%;

                    .id-grid-context-menu-item {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        .id-grid-context-menu-item-icon {
                            font-size: 14px !important;
                            width: 40px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                        }
                        .id-grid-context-menu-item-title {
                            line-height: $menu-item-height;
                            flex: 1;
                            padding-right: 16px;
                        }

                        &.id-grid-context-menu-item-skeleton {
                            padding: 5px 0px;
                            height: $menu-item-height;
                            .id-grid-context-menu-item-title {
                                overflow: hidden;
                            }
                            .ant-skeleton-element {
                                display: flex;
                                overflow: hidden;
                            }
                            .ant-skeleton-input {
                                min-width: 120px !important;
                            }
                        }
                    }
                }
            }

            .ant-menu-submenu {
                &:hover {
                    background-color: #e6f7ff;
                }
                .ant-menu-submenu-title {
                    padding-left: 0px !important;
                    width: 100%;
                }
            }

            .ant-menu-submenu-arrow {
                right: 10px;
                background: transparent;
            }
        }
    }
}

.id-grid-sub-context-menu {
    .ant-menu-item,
    .ant-menu-submenu {
        height: $menu-item-height !important;
        padding: 0px !important;
        margin: 0px !important;
        display: flex;
        align-items: center;

        &:hover {
            background-color: #e6f7ff;
        }

        .ant-menu-submenu-title {
            height: $menu-item-height !important;
            line-height: $menu-item-height;
        }

        .ant-menu-title-content {
            flex: 1;
            display: flex;
            .id-grid-context-menu-item {
                width: 100%;
                display: flex;
                align-items: center;
                .id-grid-context-menu-item-icon {
                    font-size: 14px !important;
                    width: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                }
                .id-grid-context-menu-item-title {
                    line-height: $menu-item-height;
                    padding-right: 16px;
                    flex: 1;
                }
            }
        }
    }

    .ant-menu-submenu {
        .ant-menu-submenu-title {
            padding-left: 0px !important;
            width: 100%;
            &:hover {
                background-color: #e6f7ff;
            }
        }
    }
    .ant-menu-submenu-arrow {
        right: 10px;
    }
}

.ant-menu-title-content:has(.ant-popover-open) {
    background-color: var(--grid-menu-item-active-bg, #bee6f7);
}

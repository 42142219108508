.id-text {
    &.id-text-three-dot {
        overflow: hidden !important;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-box-orient: vertical;
        text-align: left;
    }
}

.id-text-tooltip {
    &.id-text-tooltip-hidden {
        display: none !important;
    }
    &.ant-tooltip-placement-bottom,
    &.ant-tooltip-placement-bottomLeft,
    &.ant-tooltip-placement-bottomRight {
        padding-top: 8px !important;
    }
}

$boder-color-grid: #e9e9e9;
$header-background-grid: #f5f5f5;
$ag-row-odd-bg: #f7fafb;
.id-table {
    max-width: 100%;
    .ant-table-content {
        & > table {
            table-layout: fixed !important;
        }
    }

    .id-table-wrap {
        position: relative;
        .ant-table-cell {
            .ant-progress-text {
                font-size: 12px;
            }
        }

        .id-table-header-cell {
            padding-left: 8px;
            padding-right: 8px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            white-space: normal;
            text-align: center;
            position: relative;
            line-height: 16px;
        }

        .ant-table-body {
            .ant-table-tbody tr:nth-child(odd) {
                td {
                    background-color: #f7fafb;
                }
            }
            .ant-table-placeholder {
                .ant-empty-description {
                    color: #333 !important;
                }
            }
        }

        .ant-table-wrapper {
            height: 100%;
            .ant-spin-nested-loading {
                height: 100%;
                .ant-spin-container {
                    height: 100%;
                    .ant-table-small {
                        height: 100%;
                        font-size: 13px;
                        .ant-table-container {
                            height: 100%;
                            display: flex;
                            flex-direction: column;

                            .ant-table-thead {
                                tr {
                                    th {
                                        border-color: $boder-color-grid !important;
                                        text-align: center;
                                        background-color: $header-background-grid !important;
                                        padding: 0px !important;
                                    }
                                }

                                .ant-table-cell {
                                    height: 32px;
                                }
                            }

                            .ant-table-body {
                                flex: 1;
                                max-height: unset !important;

                                .ant-table-tbody {
                                    tr:nth-child(odd) {
                                        background-color: #f7fafb;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .ant-table-cell {
                padding: 6px 8px !important;
            }
        }

        .not-auto-height-cell {
            overflow: hidden;

            .table-cell-text {
                display: inline-block;
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
                width: 100%;
            }
        }

        .container-filter-column {
            width: 100%;

            .text-filter {
                height: 100%;
                input {
                    outline: none;
                }
            }
        }
        .number-range-filter {
            width: 100%;
            height: 28px;
            .icon-remove {
                height: 26px;
            }
        }

        .ant-table-empty {
            .ant-table-body > table {
                height: 100%;

                .ant-empty {
                    width: 50%;
                }
            }
        }

        .ant-table-summary {
            border-right: 1px solid $boder-color-grid !important;
            .ant-table-cell {
                border-right: 1px solid $boder-color-grid !important;
            }
        }

        &.id-table-has-row-group {
            .id-table-row-group {
                background: #d7ecf2 !important;
                border: 1px solid #b1dbe7 !important;
                font-weight: 700;
            }

            .id-table-row {
                .ant-table-row-indent,
                .ant-table-row-expand-icon {
                    display: none;
                }
            }
        }

        &.id-table-not-show-header {
            .ant-table-thead {
                visibility: collapse !important;
            }
        }
    }

    .pagining {
        table {
            font-size: 13px !important;
        }

        & > div::-webkit-scrollbar {
            width: 0 !important;
            height: 0 !important;
        }
    }

    &.grid-auto-width {
        .ant-table-header {
            background: #f5f5f5;
            border: 1px solid #e9e9e9;
            .ant-table-cell {
                border-bottom: 0px !important;
                border-top: 0px !important;
                &:first-child {
                    border-left: 0px !important;
                }
                &:last-child {
                    border-right: 0px !important;
                }
            }
        }
    }
}

.min-width-column {
    min-width: 100px;
}

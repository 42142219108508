.id-segmented {
    .ant-segmented-item {
        font-size: var(--main-font-size, 13px);
        color: var(--main-input-font-color, #333);
        .ant-segmented-item-label {
            padding: 0px 10px;
            display: inline-flex;

            span:not(.ant-segmented-item-icon):last-child {
                flex: 1;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                word-break: keep-all;
            }
        }
    }
    &.id-segmented-separator {
        .ant-segmented-group {
            .ant-segmented-item {
                &:not(.ant-segmented-item-selected, :last-child) {
                    &::before {
                        content: "";
                        height: 12px;
                        width: 0.5px;
                        background: #ccc;
                        position: absolute;
                        right: 0px;
                        top: 8px;
                        transition: all 0.2s;
                    }
                }
                &:has(+ .ant-segmented-item-selected) {
                    &::before {
                        opacity: 0;
                    }
                }
            }
        }
    }
}

.id-segmented-responsive {
    width: 100%;
    overflow: hidden;
    .id-segmented {
        .ant-segmented-item {
            font-size: var(--main-font-size, 13px);
            .ant-segmented-item-label {
                padding: 0px 10px;
                min-height: 28px;
                padding: 0 10px;
                line-height: 28px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                word-break: keep-all;
                display: flex;
                align-items: center;
                justify-content: center;
                .ant-segmented-item-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 16px;
                }
                span:not(.ant-segmented-item-icon):last-child {
                    flex: 1;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    word-break: keep-all;
                }
            }
        }
    }

    .id-segmented-other {
        position: relative;
        padding: 0px 11px;
        background: rgb(245, 245, 245);
        display: flex;
        align-items: center;
        height: 32px;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        font-size: var(--main-font-size, 13px);
        color: var(--main-input-font-color, #333);
        &.id-segmented-other-separator {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            &::before {
                content: "";
                height: 12px;
                width: 0.5px;
                background: #ccc;
                position: absolute;
                left: 0px;
                top: 10px;
                transition: all 0.2s;
            }
        }
    }
}

.number-range-filter {
    .number-range-label {
        display: inline-block;
        width: 50px;
        padding-right: 5px;
        text-align: right; 
        color: #333;
        font-size: 12px;
    }

    .number-range-input {
        height: 30px;
        border-radius: 5px;
        margin: 5px;
        padding: 5px;
        border: 1px solid rgb(204, 204, 204);
        box-shadow: none;
        outline: 0px;

        &:focus {
            border: 1px solid #0088fe;
        }
    }
}
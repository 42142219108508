.wrap-loading {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .lds-facebook {
        display: inline-block;
        position: relative;
        width: 64px;
        height: 64px;
    }

    .lds-facebook div {
        display: inline-block;
        position: absolute;
        left: 6px;
        width: var(--main-font-size, 13px);
        background-image: linear-gradient(to right, rgb(0, 84, 216), rgb(92, 156, 255));
        animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }

    .lds-facebook div:nth-child(1) {
        left: 6px;
        animation-delay: -0.24s;
    }

    .lds-facebook div:nth-child(2) {
        left: 26px;
        animation-delay: -0.12s;
    }

    .lds-facebook div:nth-child(3) {
        left: 45px;
        animation-delay: 0;
    }

    @keyframes lds-facebook {
        0% {
            top: 6px;
            height: 51px;
        }

        50%,
        100% {
            top: 19px;
            height: 26px;
        }
    }
}

#root {
    height: 100%;
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 25px;
}

.lds-ellipsis div {
    position: absolute;
    top: 5px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: var(--main-color);
    // background-image: linear-gradient(to right, rgb(0, 84, 216), rgb(92, 156, 255));
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(19px, 0);
    }
}

.bg-opacity {
    background: #fff !important;
}

.idtek-loading {
    .idtek-loading-body-wrap {
        position: relative;
        height: 100%;
        width: 100%;
        overflow: hidden;
        pointer-events: none;
    }

    &.idtek-loading-visible {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.25s linear;
    }

    &.idtek-loading-hidden {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s 0.25s, opacity 0.25s linear;
    }
}

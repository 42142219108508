.rc-color-picker {
  z-index: 1051;
}

.color-picker-container {
  display: flex;
  align-items: center;
  min-width: 130px;
  position: relative;
  .color-picker-input {
    flex: 1 1 0%;
    border: 0px;
    padding: 4px 8px;
    height: 100%;
    outline: 0px;
    background: transparent;
    text-align: left;

    color: var(--main-input-font-color);
    font-size: var(--main-input-font-size, 13px);
    font-weight: var(--main-input-font-weight);
    width: 100%;

    .icon-remove {
      position: absolute;
      right: 30px;
    }
  }
  .color-picker-handle {
    position: absolute;
    top: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    right: 7px;
  }
  .color-picker-grey {
    opacity: 0.5;
  }
}

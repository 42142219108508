.id-skeleton-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    display: inline-block;
    .ant-skeleton-element {
        display: flex;
        align-items: center;
        justify-content: center;
        .ant-skeleton-avatar-square {
            border-radius: 2px;
            &::after {
                border-radius: 2px;
            }
        }
    }
}

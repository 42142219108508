.cell-grid-editable-date {
    .body-wrap {
        border: 0px !important;
        min-height: unset !important;
        height: 100% !important;
        box-shadow: none !important;
        .ant-picker {
            height: 100% !important;
            padding-left: 11px !important;
            padding-right: 11px !important;
            .ant-picker-clear {
                .icon-remove {
                    height: 100% !important;
                }
            }
        }
    }
}

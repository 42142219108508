
$boder-color-grid: #e9e9e9;
.pagining {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $boder-color-grid;
    background: #f5f7f7;
    border-top: 0px !important;
    overflow: hidden;
    overflow-x: auto;
    padding: 0px 10px;

    .dropdown-page-size {
        display: flex;
        align-items: center;
        background: #fff;
        border: 1px solid #ddd;
        border-radius: 3px;
        height: 25px;

        .rc-select-selection {
            border-radius: 3px;
        }

        .rc-select-arrow {
            height: 100%;
            display: flex;
            align-items: center;
            pointer-events: unset;
            cursor: pointer;
            position: relative;
            right: 5px !important;
        }

        &.rc-select-focused {
            div.rc-select-selector {
                border-color: #e9e9e9 !important;
            }
        }

        // Version 11
        .rc-select-selector {
            border: 0px solid #ddd !important;
            border-radius: 3px;
            height: 25px;

            .rc-select-selection-search-input {
                height: 100%;
            }

            .rc-select-selection-item {
                top: 0px;
                bottom: 0px;
                left: 8px;
                display: flex;
                align-items: center;
                font-size: 13px;
                right: 16px;
                justify-content: center;
            }
        }
    }

    .dropdown-page-size-list {
        border-color: #e9e9e9 !important;

        div {
            max-height: none !important;
            overflow-y: unset !important;
        }

        .rc-select-item-option-state {
            right: 5px !important;
        }

        .rc-select-item-option-selected,
        .rc-select-item-option-active {
            background-color: var(--main-color-hover-option-selected);
            color: var(--main-font-color-hover-option-selected);
        }
    }

    td,
    .info-total {
        white-space: nowrap;
    }

    .button-pagining {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .anticon {
            font-size: 13px;
        }
    }

    button {
        outline: 0px;
        border: 0px;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;

        .pagining-action {
            width: 35px;
            text-align: center;
            font-size: 18px;
            color: #555;
            cursor: pointer;
        }
    }

    .pagining-number-page {
        width: 50px;
        text-align: center;
        height: 25px;
        border-radius: 3px;
        margin: 0px 10px;
        border: 1px solid rgb(5, 180, 213);
    }
}

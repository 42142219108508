$height-detail: 24px;
$color-detail: #333;
.input-detail {
    &.body-wrap {
        border: 0px !important;
        box-shadow: none !important;
        color: $color-detail !important;
        font-weight: 600 !important;

        .icon-remove,
        .wrap-icon-field {
            display: none !important;
        }

        &.file-picker {
            .grid-file-item-button-add {
                display: none;
            }
        }

        &.id-mentions {
            .ant-mentions {
                height: 100%;
                .rc-textarea {
                    padding: 0px !important;
                    pointer-events: none !important;
                    font-size: var(--main-input-font-size, 13px);
                    color: $color-detail !important;
                    font-weight: 600 !important;
                    min-height: $height-detail !important;
                    line-height: $height-detail;
                    height: 100%;
                }
            }
        }
    }

    &.textbox,
    &.body-select,
    &.id-tree-select,
    &.id-checkbox,
    &.id-radio,
    &.id-switch,
    &.time-picker,
    &.date-single,
    &.date-time-single,
    &.month-single,
    &.date-range,
    &.single-year,
    &.month-range,
    &.year-range,
    &.auto-complete {
        height: $height-detail !important;
    }

    &.body-wrap,
    &.icon-picker {
        min-height: $height-detail !important;
    }

    &.file-picker {
        &.file-picker-single {
            height: $height-detail !important;
        }
    }

    &.textarea {
        margin-top: 3px;
        height: 100% !important;
        &:focus-within {
            border: 0px !important;
            box-shadow: 0px !important;
        }
        .textarea-input {
            padding: 0px !important;
            font-size: var(--main-input-font-size, 13px);
            resize: none !important;
            line-height: 20px;
            color: $color-detail;
            font-weight: 600 !important;

            &::-webkit-scrollbar {
                cursor: default !important;
                width: 12px;
            }

            &::-webkit-scrollbar:horizontal {
                cursor: default !important;
                height: 0px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 3px;
                border: 2px solid white;
                background-color: #c1c1c1;
                cursor: default !important;
            }

            &::-webkit-scrollbar-track {
                background-color: #fff;
                border-radius: 3px;
                cursor: default !important;
            }
        }
    }

    &.id-rich-text {
        margin-top: 3px;
        .tox-tinymce {
            border: 0px !important;
        }

        .mce-content-readonly {
            & > p {
                padding: 0px !important;
            }
        }
    }

    &.id-checkbox-list,
    &.id-radio-list {
        min-height: $height-detail !important;
        display: flex;
        align-items: center;
        * {
            cursor: default;
        }
    }

    ////
    .id-detail-value {
        width: 100%;
        font-size: var(--main-input-font-size, 13px);
        color: $color-detail;
        font-weight: 600 !important;
        display: flex;
        align-items: center;
        min-height: $height-detail !important;
        .id-detail-value-list {
            margin: 0px;
            padding: 0px 0px 0px 15px;
            overflow: hidden;
            li {
                padding: 5px 0px;
                list-style: auto;
            }
        }
        .id-detail-value-link {
            height: 100%;
            color: $color-detail !important;
            font-weight: 600 !important;
            overflow: hidden;
            display: flex;
            &:hover {
                color: #1890ff !important;
                cursor: pointer;
            }
        }
    }
}

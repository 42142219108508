.body-wrap {
    border: 1px solid var(--main-border-color);
    border-radius: var(--main-input-border-radius);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    position: relative;
    min-height: var(--main-input-line-height, 30px);
    background: #fff;
    overflow: hidden;

    &.body-wrap-animtion {
        background: linear-gradient(
            90deg,
            rgba(190, 190, 190, 0.2) 25%,
            rgba(129, 129, 129, 0.24) 37%,
            rgba(190, 190, 190, 0.2) 63%
        );
        background-size: 400% 100%;
        -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
        animation: ant-skeleton-loading 1.4s ease infinite;
        border: 0px !important;
    }

    .wrap-icon-field {
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(var(--main-input-line-height) - 2px);
        .anticon,
        .icon-field {
            color: var(--main-input-border-color);
        }
    }
    &:hover {
        border-color: var(--main-input-border-color) !important;
        box-shadow: none !important;
        .icon-field-line {
            border-color: var(--main-input-border-color) !important;
        }
    }
    &:focus-within {
        box-shadow: 0 0 4px var(--main-input-border-color) !important;
        border-color: var(--main-input-border-color);
        .icon-field-line {
            border-color: var(--main-input-border-color) !important;
        }
    }
    .css-1g6gooi {
        margin: 0px 2px;
    }
    .rc-tree-select,
    .ant-select-selector,
    .css-10nd86i,
    .css-vj8t7z,
    .css-2o5izw {
        border: 0px !important;
        box-shadow: none !important;
        height: 100%;
        width: 100%;
        min-height: calc(var(--main-input-line-height) - 2px);
    }
    .ant-select-selector,
    .ant-picker {
        height: calc(var(--main-input-line-height) - 2px) !important;
        border: 0px !important;
    }
    .ant-picker:hover,
    .ant-picker-focused {
        border: 0px !important;
        box-shadow: none;
    }
    .css-15k3avv {
        box-shadow: 0 0 4px var(--main-input-border-color) !important;
        z-index: 999 !important;
        &::before {
            position: absolute;
            top: -6px;
            left: 5px;
            content: "";
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 6px solid #ffffff;
        }
    }
    button {
        &:focus {
            outline: 0px;
        }
    }
    .ant-picker-suffix {
        pointer-events: visible;
    }
}

.input-disabled {
    pointer-events: none;
    opacity: 0.8;
    background-color: #f5f5f5 !important;
    background: #f5f5f5 !important;
    .ant-picker-suffix {
        pointer-events: none;
    }
    .icon-remove,
    .placeholder {
        display: none;
    }
}

.input-area-disabled {
    opacity: 0.8;
    background-color: #f5f5f5 !important;
    background: #f5f5f5 !important;
    .ant-picker-suffix {
        pointer-events: none;
    }
    .icon-remove,
    .placeholder {
        display: none;
    }
}

.read-only,
.input-readonly {
    opacity: 1;
    pointer-events: none;
    &:has(.id-select-avatar-base) {
        pointer-events: unset !important;
    }
    .ant-picker-suffix {
        pointer-events: none;
    }
    .css-xwjg1b {
        padding-right: 2px;
    }
    .placeholder,
    .icon-remove,
    .mce-top-part,
    .css-1alnv5e {
        display: none;
    }

    .css-xwjg1b {
        padding-right: 2px;
    }

    .ant-select-clear {
        display: none;
    }
}

.read-only-clock {
    pointer-events: none;
    opacity: 1;
    background-color: #f0f0f0 !important;
    .ant-picker-suffix {
        pointer-events: none;
    }
    .css-xwjg1b {
        padding-right: 2px;
    }
    .placeholder,
    .icon-remove,
    .css-1alnv5e {
        display: none;
    }
}

.wrap-icon-field {
    .icon-field-line {
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    .icon-field {
        color: var(--main-icon-font-color);
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
}

.three-dot {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: var(--main-color) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: var(--main-color) !important;
}

.ant-picker-today-btn {
    color: var(--main-color) !important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background: var(--main-color-hover-option-selected, #e6f7ff) !important;
    color: var(--main-font-color-hover-option-selected, #333) !important;
}

.show-scrollbar {
    display: block !important;
}

.ant-picker-dropdown {
    font-size: var(--main-font-size, 13px);
    .ant-picker-cell-inner {
        width: unset !important;
    }
}

.id-radio-list {
    .ant-radio-group {
        .ant-space {
            flex-wrap: wrap;
        }
    }
    .ant-radio-wrapper {
        color: var(--main-font-color, #333);
        font-size: var(--main-font-size, 13px);
    }
}

.idtek-fieldset {
    border: 1px solid rgb(221, 221, 221);
    padding: 0px 10px 10px 10px;
    border-radius: 5px !important;
    min-width: 100%;
    legend {
        width: unset !important;
        padding: 0px 10px !important;
        font-size: var(--main-font-size, 13px) !important;
        color: var(--main-color);
        user-select: none;
    }
    .idtek-fieldset-body {
        transition: all 350ms ease;
    }
    &.fieldset-collapsed {
        padding-block: 0px !important;
        border-bottom-color: transparent !important;
        border-right-color: transparent !important;
        border-left-color: transparent !important;
        border-radius: 0px !important;
        background: transparent !important;

        .idtek-fieldset-body {
            overflow: hidden !important;
        }
    }
    &.fieldset-collapsed-not-animation {
        height: 20px !important;
        border-top: 1px solid rgb(221, 221, 221) !important;
        border-radius: 0px !important;
        overflow: hidden !important;
        background: transparent !important;

        border-bottom: 0px !important;
        border-right-color: transparent !important;
        border-left-color: transparent !important;
        border-radius: 0px !important;
        background: transparent !important;

        .idtek-fieldset-body {
            overflow: hidden !important;
            height: 0px !important;
        }
        padding: 5px 10px !important;
        & > legend {
            margin-bottom: 0px !important;
        }
    }
    &.fieldset-none-bordered {
        border: 0px !important;
    }
}

.fs-container-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border-radius: 3px;
    margin-right: 8px;

    .anticon {
        font-size: 11px;
        color: #fff;
        font-weight: bold;
    }
}

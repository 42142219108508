.id-tree-select {
    display: flex;

    .ant-select {
        width: 100%;
        border-radius: 3px;
        background: #ffffff;
        font-size: var(--main-font-size, 13px);
        .ant-select-selector {
            border: 0px;
            padding-left: 8px;
            background: transparent;
        }

        .ant-select-arrow {
            width: 35px;
            right: 0;
        }

        .ant-select-clear {
            opacity: 1;
            right: 35px;
            top: 0px;
            width: 30px;
            height: 100% !important;
            overflow: hidden;
            margin-top: 0px;
        }

        .ant-select-selection {
            &:hover {
                border-color: var(--main-color);
            }
        }

        &.ant-select-single {
            .ant-select-selection-search-input {
                height: var(--main-input-line-height, 30px) !important;
                line-height: calc(var(--main-input-line-height, 30px) - 2px);
            }

            .ant-select-selector {
                cursor: pointer !important;
                .ant-select-selection-item {
                    width: 1px;
                    line-height: calc(var(--main-input-line-height, 30px) - 2px);
                }
                .ant-select-selection-search {
                    left: 8px !important;
                }
                .ant-select-selection-placeholder {
                    color: rgba(0, 0, 0, 0.25) !important;
                    line-height: calc(var(--main-input-line-height, 30px) - 2px);
                    font-size: var(--main-font-size, 13px);
                }
            }

            .anticon {
                vertical-align: middle !important;
            }
        }
    }

    /// New Version
    .anticon {
        vertical-align: middle !important;
    }

    .ant-tree-select {
        width: 100%;

        .ant-select-selector {
            background: #fff;
            padding: 0px 8px;
            cursor: pointer !important;
            .ant-select-selection-search {
                .ant-select-selection-search-input {
                    height: 100%;
                }
            }

            .ant-select-selection-item {
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
                display: inline-block;
                font-size: var(--main-font-size, 13px);
                width: 1px;
                padding-right: 60px;
                line-height: calc(var(--main-input-line-height, 30px) - 2px);
                align-items: center;
            }
        }
        .ant-select-arrow {
            height: 100%;
            top: 0;
            margin-top: 0px;
            right: 0px;
            width: 35px;
            display: flex;
            align-items: center;

            .anticon:not(.ant-select-suffix) {
                pointer-events: none;
            }
        }
        .ant-select-clear {
            opacity: 1;
            right: 35px;
            top: 0px;
            height: 100%;
            margin-top: 0px;
            width: 30px;
        }

        .ant-select-selection-item-content {
            font-size: 12px;
        }
    }

    // Multiple
    .ant-select-multiple {
        .ant-select-selector {
            min-height: calc(var(--main-input-line-height) - 2px) !important;
            height: unset !important;

            .ant-select-selection-search {
                margin-left: 2px;
            }

            .ant-select-selection-placeholder {
                color: rgba(0, 0, 0, 0.25) !important;
                line-height: calc(var(--main-input-line-height, 30px) - 2px);
                font-size: var(--main-font-size, 13px);
            }

            .ant-select-selection-item {
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
                display: inline-flex;
                font-size: var(--main-font-size, 13px);
                width: unset;
                max-width: 200px;
                padding-right: unset;
                line-height: calc(var(--main-input-line-height, 30px) - 2px);
            }
        }

        .ant-select-selection-item {
            padding-right: 0px !important;
            .ant-select-selection-item-remove {
                width: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover {
                    background-color: #ffbdad;
                    color: #de350b;
                }
            }
        }
    }

    &.icon-invisible {
        .ant-select-clear {
            opacity: 1;
            right: 0px !important;
        }
        .ant-select-arrow {
            display: none !important;
        }
        .ant-select-selection-item {
            padding-right: 30px !important;
        }
    }
}

.id-tree-select-dropdown {
    font-size: var(--main-font-size, 13px);
    color: #333;

    .ant-select-tree-switcher-leaf-line {
        display: none;
    }

    .ant-select-tree-list-holder-inner {
        .ant-select-tree-treenode:first-child {
            .ant-select-tree-title {
                margin-top: -2px;
            }
        }
    }

    .ant-select-tree-treenode {
        display: flex;
        align-items: center;
        padding-bottom: 0px !important;
        padding-top: 10px !important;
    }

    .ant-select-tree-indent {
        margin-top: -10px !important;
    }

    .ant-select-tree-title {
        line-height: 16px;
        font-size: var(--main-font-size, 13px);
        padding: 3px 0px;
    }

    .ant-select-tree-node-content-wrapper {
        line-height: 16px !important;
        padding: 0px 5px !important;
        display: flex;
        align-items: center;
    }

    .ant-select-tree-indent-unit {
        &::before {
            border: 0px !important;
        }

        display: inline-block;
        width: 30px;
        border-right: 1px dashed #888;
        margin-left: -17px;
        margin-top: -11px;
        height: 100%;

        &.ant-select-tree-indent-unit-end {
            &:not(:last-child) {
                border-right: 1px dashed #888;
            }
        }
    }

    &.id-tree-has-root-node {
        .ant-select-tree-indent-unit {
            &::before {
                border: 0px !important;
            }

            display: inline-block;
            width: 30px;
            border-right: 1px dashed #888;
            margin-left: -17px;
            margin-top: -11px;

            &.ant-select-tree-indent-unit-end {
                &:not(:last-child) {
                    border-right: 1px dashed #888;
                }
            }
            &.ant-select-tree-indent-unit-start {
                &:first-child {
                    border-right: 1px dashed #888;
                }
            }
        }

        .ant-select-tree-node-content-wrapper-open {
            position: relative;
            &::after {
                border-right: 1px dashed #888;
                width: 1px;
                left: -12px;
                right: 0px;
                bottom: 3px;
                top: calc(50% + 8px);
                content: "";
                position: absolute;
                z-index: 2;
            }
        }

        .ant-select-tree-list-holder-inner {
            .ant-select-tree-treenode-disabled:first-child {
                .ant-select-tree-switcher_open {
                    &::after {
                        border-right: 1px dashed #888;
                        width: 1px;
                        left: 12px;
                        right: 0px;
                        bottom: -23px;
                        top: 12px;
                        content: "";
                        position: absolute;
                        z-index: 1;
                    }
                }
            }
        }
    }

    .ant-select-tree-switcher-noop {
        svg {
            display: none !important;
        }
    }

    .ant-select-tree-switcher {
        position: relative;
        z-index: 2;

        &::before {
            border-bottom: 1px dashed #888;
            height: 1px;
            left: 0px;
            right: 0px;
            top: 12px;
            content: "";
            position: absolute;
            z-index: 1;
        }

        .ant-select-tree-switcher-line-icon {
            z-index: 99999;
            position: absolute;
            top: 5px;
            right: 4px;
            background: #fff;
        }
    }

    .ant-select-empty {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333;
    }

    .ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected,
    .ant-select-tree .ant-select-tree-node-content-wrapper:hover {
        background-color: var(--main-color-hover-option-selected, #e6f7ff) !important;
        color: var(--main-font-color-hover-option-selected, #333) !important;
    }

    .ant-select-tree-treenode-disabled {
        .ant-select-tree-node-content-wrapper {
            pointer-events: none;
            background-color: #fff !important;
        }
    }

    .ant-select-tree-node-content-wrapper-open,
    .ant-select-tree-node-content-wrapper-close {
        .ant-select-tree-title {
            font-weight: bold;
        }
    }
}
